<template>
  <div class="h-full">
    <div
      class="flex items-center justify-between fixed top-0 p-[15px] left-0 right-[0] w-full z-[9999]"
    >
      <span
        class="flex flex-row justify-center items-center"
        :class="{ 'opacity-0 pointer-events-none': step != 3 }"
      >
        <div class="text-xs" style="font-family: var(--sans)" @click="step = 2">
          Back
        </div>
      </span>

      <span class="flex flex-row justify-center items-center">
        <RouterLink replace to="/guardian" class="text-xs">
          Not now
        </RouterLink>
      </span>
    </div>

    <div v-if="step === 1" class="h-full flex flex-col justify-between -mt-4">
      <div>
        <img
          alt="Funds Icon"
          src="../../assets/images/funds-icon.svg"
          class="mb-5 w-[80px]"
        />
        <h1
          class="text-left text-[1.4rem] font-bold text-primary leading-tight"
        >
          How would you like to pay?
        </h1>
        <p class="text-left text-[1.35rem] font-medium mb-3 text-black">
          Please select your app
        </p>
        <div class="flex flex-wrap -mx-2 overflow-hidden mt-10">
          <button
            v-for="bank in paginatedOrders"
            :key="bank.id"
            :class="{ activeBank: selectedBankId === bank.id }"
            class="my-2 px-2 w-1/3 overflow-hidden rounded-3xl opacity-75"
            @click="selectBank(bank)"
          >
            <img
              alt="Bank Logo"
              :src="require(`../../assets/images/bank-logos/${bank.id}.jpg`)"
              class="w-full rounded-3xl shadow mb-1"
            />
          </button>
          <button
            v-if="currentPage * maxPerPage < banks.length"
            class="my-2 px-2 w-1/3 overflow-hidden rounded-3xl text-xl text-[#32A1EB] font-sans"
            style="font-family: var(--sans) !important"
            @click="loadMore"
          >
            More..
          </button>
        </div>
      </div>

      <div class="mt-[50px] flex justify-end items-center">
        <button
          class="rounded-full text-white px-12 py-3 font-open-sans order-1"
          :style="[
            selectedBankId != null
              ? { background: 'var(--main)' }
              : { background: '#D2D2D2' },
          ]"
          @click="step = 2"
        >
          Continue
        </button>
      </div>
    </div>
    <div v-if="step === 2" class="h-full flex flex-col justify-between -mt-4">
      <div>
        <img
          alt="Funds Icon"
          src="../../assets/images/funds-icon.svg"
          class="mb-5 w-[80px]"
        />
        <h1
          class="text-left text-[1.4rem] font-bold text-primary leading-tight"
        >
          How would you like to pay?
        </h1>
        <p class="text-left text-[1.35rem] font-medium mb-5 text-black">
          Please enter the amount you<br />
          would like to transfer
        </p>
        <p class="text-[#909090]">
          All transactions are non-refundable once the transaction is complete.
          Please read our terms and conditions for more information.
        </p>

        <div class="mt-12">
          <label for="amount">Transaction Amount</label>
          <input
            id="amount"
            v-model="amount"
            type="number"
            class="w-full border border-[#E5E5E5] mt-1 rounded-xl p-5"
          />
        </div>
      </div>

      <div class="mt-[50px] flex justify-end items-center">
        <button
          class="bg-primary rounded-full text-white px-12 py-3 font-open-sans order-1"
          :style="[
            amount > 0
              ? { background: 'var(--main)' }
              : { background: '#D2D2D2' },
          ]"
          @click="step = 3"
        >
          Continue
        </button>
      </div>
    </div>
    <div v-if="step === 3" class="h-full flex flex-col -mt-4">
      <div class="text-center text-[#52CBF1] font-bold">Give Consent</div>
      <img
        src="../../assets/images/logo-color.png"
        class="w-[100px] mx-auto mt-2"
        alt="Logo"
      />

      <p class="text-center text-[#606060] font-bold mt-8">Paying to</p>
      <div class="bg-[#EBEBED] rounded-xl p-6 mt-2">
        <div class="mb-4">
          <div class="-mb-1">Account Name</div>
          <div class="font-bold text-black">Sonik Pocket</div>
        </div>

        <div class="mb-6">
          <div class="-mb-1">Payment Reference</div>
          <div class="font-bold text-black">ABC-456-789</div>
        </div>

        <div class="flex items-center justify-between font-bold text-[#52CBF1]">
          <div>Total to pay GBP</div>
          <div>&pound;{{ amount }}</div>
        </div>
      </div>

      <p class="text-center text-[#606060] font-bold mt-6">Paying from</p>
      <div
        class="bg-[#EBEBED] rounded-xl p-6 mt-2 flex justify-between items-center"
      >
        <div>
          <div class="-mb-1">Your Bank</div>
          <div
            class="font-bold text-black overflow-ellipsis w-[150px] whitespace-nowrap overflow-hidden"
          >
            {{ selectedBankName }}
          </div>
        </div>
        <img
          alt="Bank Logo"
          :src="require(`../../assets/images/bank-logos/${selectedBankId}.jpg`)"
          class="rounded shadow mb-1 w-[50px] h-[50px]"
        />
      </div>

      <p class="text-center text-xs font-semibold mt-2 px-2">
        You’ll be securely redirected to your bank to authenticate this payment.
      </p>

      <div
        class="text-center text-xs border border-[#D4D4D4] rounded-xl p-4 mt-8 font-semibold"
      >
        By continuing you are permitting our partner Modulr FS Limited to
        initiate a payment from your bank account and also agree to their
        <span class="text-[#52CBF1]">terms and conditions</span> and
        <span class="text-[#52CBF1]">privacy policy.</span>
      </div>

      <div class="mt-[50px] flex justify-center items-center">
        <button
          class="bg-primary rounded-full text-white px-12 py-3 font-open-sans order-1 mb-4"
          @click="handleSubmit"
        >
          Confirm & Continue
        </button>
      </div>
    </div>
    <div v-if="step === 4" class="h-full flex flex-col justify-between -mt-4">
      <div>
        <img src="../../assets/images/funds-icon.svg" class="mb-5 w-[80px]" alt="Funds Icon" />
        <h1
          class="text-left text-[1.4rem] font-bold text-primary leading-tight"
        >
          Confirm
        </h1>
        <p class="text-left text-[1.35rem] font-medium mb-5 text-black">
          your purchase
        </p>
        <p class="text-[#909090]">
          Follow the on-screen instructions to confirm.
        </p>

        <div class="mt-16 mx-auto w-full">
          <svg
            width="60"
            height="60"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            class="mx-auto"
          >
            <defs>
              <linearGradient
                id="a"
                x1="8.042%"
                y1="0%"
                x2="65.682%"
                y2="23.865%"
              >
                <stop stop-color="#36d5f2" stop-opacity="0" offset="0%" />
                <stop
                  stop-color="#36d5f2"
                  stop-opacity=".631"
                  offset="63.146%"
                />
                <stop stop-color="#36d5f2" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="none" fill-rule="evenodd">
              <g transform="translate(1 1)">
                <path
                  id="Oval-2"
                  d="M36 18c0-9.94-8.06-18-18-18"
                  stroke="url(#a)"
                  stroke-width="2"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="0.9s"
                    repeatCount="indefinite"
                  />
                </path>
                <circle fill="#36d5f2" cx="36" cy="18" r="1">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="0.9s"
                    repeatCount="indefinite"
                  />
                </circle>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import config from "../../config";

  export default {
    data() {
      return {
        step: 1,
        banks: [],
        selectedBankId: null,
        selectedBankName: null,
        currentPage: 1,
        maxPerPage: 8,
        showReadMore: true,
        amount: 0,
      };
    },
    computed: {
      getImage(img) {
        console.log(img);
        return require(`../../assets/images/bank-logos/${img}`);
      },
      totalResults() {
        return Object.keys(this.banks).length;
      },
      pageCount() {
        return Math.ceil(this.totalResults / this.maxPerPage);
      },
      pageOffest() {
        return this.maxPerPage * this.currentPage;
      },
      paginatedOrders() {
        return this.banks.slice(0, this.currentPage * this.maxPerPage);
      },
    },
    created() {
      this.fetchBanks();
    },
    methods: {
      async fetchBanks() {
        const response = await axios.get("modulr/pisp/asps/list");
        this.banks = response.data;
      },
      selectBank(bank) {
        this.selectedBankId = bank.id;
        this.selectedBankName = bank.name;
      },
      handleSubmit() {
        const token = localStorage.getItem("guardian_token");
        const userId = localStorage.getItem("guardian_user_id");
        window.open(
          `${config.HOST}api/modulr/pisp/payment/init?userId=${userId}&token=${token}&aspspId=${this.selectedBankId}&paymentValue=${this.amount}`,
          "_blank"
        );

        this.step = 4;

        setTimeout(() => {
          this.$router.replace("/guardian");
        }, 2000);
      },
      loadMore() {
        this.currentPage += 1;
      },
    },
  };
</script>

<style scoped>
  .activeBank {
    transform: scale(1.15);
    transition: all 100ms ease-in-out;
    opacity: 1;
  }
</style>
